import SEO from '../components/seo';
import Layout from '../containers/layout';
import styled from 'styled-components';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import Main from '../components/main';
import { PHOTOS } from '../constants/content';

const ImageGalleryContainer = styled.div`
  .image-gallery-image {
    height: 300px;
    object-fit: cover;
  }

  .image-gallery-thumbnail-image {
    object-fit: cover;
    height: 50px;
  }

  @media (min-width: 600px) {
    .image-gallery-image {
      height: 700px;
    }
  }
  @media (min-width: 1200px) {
    .image-gallery-image {
      height: 900px;
    }
  }
`;

const Photos = () => {
  const images = [
    { original: '/images/kueche2.jpg' },
    { original: '/images/kueche.jpg' },
    { original: '/images/wohnzimmer2.jpg' },
    { original: '/images/wohnzimmer.jpg' },
    { original: '/images/flur.jpg' },
    { original: '/images/badezimmer.jpg' },
    { original: '/images/flur2.jpg' },
    { original: '/images/schlafzimmer.jpg' },
    { original: '/images/schlafzimmer2.jpg' },
    { original: '/images/toilette.jpg' },
    { original: '/images/garten.jpg' },
    { original: '/images/garten2.jpg' },
  ];

  return (
    <Layout currentPage="photos">
      <SEO title={PHOTOS.TITLE} description={PHOTOS.DESCRIPTION} keywords={PHOTOS.KEYWORDS} />
      <Main>
        <ImageGalleryContainer>
          <ImageGallery
            items={images.map(image => ({
              ...image,
              thumbnail: image.original,
            }))}
            showBullets={true}
            thumbnailPosition={'bottom'}
            showPlayButton={false}
            showIndex={true}
            showFullscreenButton={false}
          />
        </ImageGalleryContainer>
      </Main>
    </Layout>
  );
};

export default Photos;
